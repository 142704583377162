<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>
			{{ $t('documents.templates') }}
			<v-spacer />
			<SettingsDocumentsTemplatesNew :loading-parent="loading" />
		</v-card-title>
		<v-card-text>
			<p>{{ $t('documents.templatesExplanation1') }}</p>
			<p>{{ $t('documents.templatesExplanation2') }}</p>
			<v-list two-line>
				<SettingsDocumentsTemplatesItem v-for="template in templates" :key="`template${template.id}`" :template="template" />
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsDocumentsTemplates',
	data() {
		return {
			loading: true,
		}
	},
	components: {
		SettingsDocumentsTemplatesNew: () => import('@/components/settings/SettingsDocumentsTemplatesNew.vue'),
		SettingsDocumentsTemplatesItem: () => import('@/components/settings/SettingsDocumentsTemplatesItem.vue'),
	},
	computed: {
		...mapGetters({
			templates: 'documents/templates',
		}),
	},
	created() {
		this.fetchDocumentsTemplates().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('documents', ['fetchDocumentsTemplates']),
	},
}
</script>
